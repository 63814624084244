import { useGqlQuery } from '@/shared/hooks/useGqlQuery';
import FlipAmount from '@/shared/utils/FlipAmount';
import type TokenAmount from '@/shared/utils/TokenAmount';
import getLatestFlipSupplyQuery from '../queries/flipSupplyQueries';

export interface FlipSupplyHook {
  totalFlipSupply: string | undefined;
  annualSystemCompoundedRewards: TokenAmount | undefined;
  isLoading: boolean;
  error: Error | undefined;
}

const useFlipSupply = (): FlipSupplyHook => {
  const { isLoading, error, data } = useGqlQuery(getLatestFlipSupplyQuery, {
    context: { clientName: 'statechainCache' },
  });
  const flipSupply = data?.flipSupply;
  const totalFlipSupply = FlipAmount.from(flipSupply?.totalIssuance);

  const annualSystemCompoundedRewards =
    FlipAmount.from(flipSupply?.annualSystemCompoundedRewards) ?? undefined;

  return {
    totalFlipSupply: totalFlipSupply?.toFormatted('numeral'),
    annualSystemCompoundedRewards,
    isLoading,
    error: error ?? undefined,
  };
};

export default useFlipSupply;
