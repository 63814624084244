import { FlipLabel, StatPanel } from '@/shared/components';
import AuctionWidget from '@/shared/components/AuctionWidget';
import QuestionMarkTooltip from '@/shared/components/QuestionMarkTooltip';
import { useEpochsWithRotation } from '@/shared/hooks';
import { type AuctionInfo } from '@/shared/hooks/useAuction';
import AuctionIcon from '@/shared/icons/Auction';
import { BankNoteIcon } from '@/shared/icons/large';
import LockIcon from '@/shared/icons/Lock';
import RewardsIcon from '@/shared/icons/Rewards';
import { FLIP_SYMBOL, TokenAmount } from '@/shared/utils';
import useFlipSupply from '../../hooks/useFlipSupply';

type AuctionStatPanelsProps = {
  auction: AuctionInfo | null;
  error: Error | undefined;
  isLoading: boolean;
  qualifiedNodes: number;
};

const AuctionStatPanels: React.FC<AuctionStatPanelsProps> = ({
  auction,
  isLoading,
  qualifiedNodes,
}) => {
  const { annualSystemCompoundedRewards } = useFlipSupply();
  const { epochsWithRotation, isLoading: epochsLoading } = useEpochsWithRotation({
    pollInterval: 60 * 1000,
  });

  const currentEpochWithRotation = epochsWithRotation?.[0];
  const previousEpochWithRotation = epochsWithRotation?.[1];
  return (
    <div className="grid grid-cols-1 gap-3 lg:grid-cols-2 lg:gap-6">
      <AuctionWidget
        auction={auction}
        loading={epochsLoading}
        epochWithRotation={currentEpochWithRotation ?? null}
        previousEpochWithRotation={previousEpochWithRotation ?? null}
      />
      <div className="flex flex-col justify-between space-y-3 [&>div]:min-h-[80px]">
        <StatPanel isLoading={isLoading}>
          <div className="flex items-center space-x-2">
            <AuctionIcon className="stroke-cf-green-1" />
            <span className="flex items-center text-14 text-white sm:text-20">
              <span className="mr-[5px]">Min. Active Bid</span>
              <QuestionMarkTooltip
                content="MAB is the lowest winning bid if the auction were to resolve now. Learn more about"
                link="https://docs.chainflip.io/concepts/validator-network/validator-auctions-bonds-and-rewards"
              />
            </span>
          </div>
          <FlipLabel
            amount={auction?.minActiveBid ?? TokenAmount.ZERO}
            usdAmount={auction?.minActiveBidUSDC}
            className="text-12 sm:text-20"
          />
        </StatPanel>

        <StatPanel isLoading={isLoading}>
          <div className="flex items-center space-x-2">
            <RewardsIcon className="stroke-cf-green-1" />
            <span className="flex items-center text-14 text-white sm:text-20">
              <span className="mr-[5px]">Annual Rewards</span>
              <QuestionMarkTooltip
                content={`The total amount of ${FLIP_SYMBOL} issued annually.`}
              />
            </span>
          </div>
          <FlipLabel
            amount={annualSystemCompoundedRewards ?? TokenAmount.ZERO}
            large
            symbol={false}
            className="text-12 sm:text-20"
          />
        </StatPanel>

        <StatPanel isLoading={isLoading}>
          <div className="flex items-center space-x-2">
            <LockIcon className="stroke-cf-green-1" />
            <span className="flex items-center text-14 text-white sm:text-20">
              <span className="mr-[5px]">Projected Global Lockup</span>
              <QuestionMarkTooltip
                content={`The projected amount of ${FLIP_SYMBOL} which will be locked up for the entirety of the next Epoch.`}
              />
            </span>
          </div>
          <FlipLabel
            amount={auction?.projectedLockup ?? TokenAmount.ZERO}
            usdAmount={auction?.projectedLockupUSDC}
            className="text-12 sm:text-20"
          />
        </StatPanel>
        <StatPanel isLoading={isLoading}>
          <div className="flex items-center space-x-2">
            <BankNoteIcon className="text-cf-green-1" />
            <span className="flex items-center text-14 text-white sm:text-20">
              <span className="mr-[5px]">Qualified Nodes</span>
              <QuestionMarkTooltip
                content={`The total number of validators that have a 'Qualified' state. i.e can participate in keygen ceremonies and are eligible for backup rewards`}
              />
            </span>
          </div>
          <span className="text-12 text-white md:text-20">{qualifiedNodes}</span>
        </StatPanel>
      </div>
    </div>
  );
};

export default AuctionStatPanels;
