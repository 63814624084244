import { useMemo } from 'react';
import { CHAINFLIP_BLOCK_TIME_SECONDS } from '@chainflip/utils/consts';
import classNames from 'classnames';
import { type Duration, intervalToDuration } from 'date-fns';
import { AnimatedEpochProgress } from './AnimatedEpochProgress';
import { SkeletonLine } from './atoms/LoadingSkeleton';
import Button from './flip-ui-kit/Button';
import LoadingSpinner from './LoadingSpinner';
import { StatPanel } from './molecules/StatPanel';
import { Tooltip } from './molecules/Tooltip';
import QuestionMarkTooltip from './QuestionMarkTooltip';
import { RotationInfoModal } from './RotationInfoModal';
import { type EpochWithRotationFragment } from '../graphql/generated/graphql';
import { type AuctionInfo } from '../hooks/useAuction';
import useBoolean from '../hooks/useBoolean';
import Clock from '../icons/flip-ui-kit/small/Clock';
import { RotateIcon } from '../icons/large';
import { HourglassIcon, Rotate2Icon, GavelIcon } from '../icons/small';

type EpochPhase = 'epoch' | 'auction' | 'rotation' | 'safe mode';

const formatTimeRemaining = (duration: Duration | null) => {
  if (!duration) return null;
  const output = [];
  if (duration.days) output.push(`${duration.days}d`);
  if (duration.hours) output.push(`${duration.hours}h`);
  if (duration.minutes) output.push(`${duration.minutes}m`);
  return output.join(' ');
};

const getCurrentEpochPhase = (auction: AuctionInfo): { name: EpochPhase; index: number } => {
  if (!auction.authorityRotationEnabled) return { name: 'safe mode', index: 0 };
  if (auction.hasEnded) return { name: 'rotation', index: 2 };
  if (auction.percentageElapsed >= auction.redemptionPeriodAsPercentage)
    return { name: 'auction', index: 1 };
  return { name: 'epoch', index: 0 };
};

const getEpochPhaseTimeToCompletion = (auction: AuctionInfo | null, epochPhase: EpochPhase) => {
  if (!auction) return '';

  if (epochPhase === 'epoch')
    return formatTimeRemaining(
      intervalToDuration({
        start: new Date(0),
        end: new Date(auction.epochLengthBlocks * (CHAINFLIP_BLOCK_TIME_SECONDS * 1000)),
      }),
    );
  if (epochPhase === 'auction') {
    const auctionLength = (auction.epochLengthBlocks * auction.redemptionPeriodAsPercentage) / 100;
    return formatTimeRemaining(
      intervalToDuration({
        start: new Date(0),
        end: new Date(auctionLength * (CHAINFLIP_BLOCK_TIME_SECONDS * 1000)),
      }),
    );
  }

  if (epochPhase === 'rotation') {
    return '20m';
  }

  return '';
};

type Panel = {
  icon: JSX.Element;
  header: string;
  body: string;
  tooltipContent: string;
  timeToCompletion: string | null;
};
const PanelRow = ({
  panel,
  isActive,
  isCompleted,
}: {
  panel: Panel;
  isActive: boolean;
  isCompleted: boolean;
}) => {
  const opacityClasses = !isActive ? 'opacity-40' : '';

  return (
    <div className="flex flex-col space-y-0.5">
      <div className="flex w-full flex-row justify-between">
        <div className="flex flex-row items-center space-x-2">
          <QuestionMarkTooltip content={panel.tooltipContent} className="h-4 w-4 text-12" />
          <span className={opacityClasses}>{panel.icon}</span>
          <span className={opacityClasses}>{panel.header}</span>
        </div>

        <div className="flex flex-row items-center justify-center gap-x-1 font-aeonikMono text-cf-light-1">
          <Clock /> ≈ <span>{panel.timeToCompletion ?? '-'}</span>
        </div>
      </div>
      <div
        className={classNames(
          'text-12 text-cf-light-2',
          isCompleted && 'line-through',
          opacityClasses,
        )}
      >
        {panel.body}
      </div>
    </div>
  );
};

const AuctionWidget = ({
  auction,
  loading,
  epochWithRotation,
  previousEpochWithRotation,
  className,
}: {
  auction: AuctionInfo | null;
  loading: boolean;
  epochWithRotation: EpochWithRotationFragment | null;
  previousEpochWithRotation: EpochWithRotationFragment | null;
  className?: string;
}) => {
  const {
    setTrue: showCurrentRotationDetailsModal,
    setFalse: hideCurrentRotationDetailsModal,
    value: detailsModalActive,
  } = useBoolean(false);

  const {
    setTrue: showPreviousRotationDetailsModal,
    setFalse: hidePreviousRotationDetailsModal,
    value: oldDetailsModalActive,
  } = useBoolean(false);

  const timeUntilResolution = useMemo(
    () =>
      auction &&
      formatTimeRemaining(
        intervalToDuration({
          start: Date.now(),
          end: auction?.endsAt,
        }),
      ),
    [auction],
  );
  const timeUntilAuction = useMemo(
    () =>
      auction &&
      formatTimeRemaining(
        intervalToDuration({
          start: Date.now(),
          end: auction?.startsAt,
        }),
      ),
    [auction],
  );

  const panels = useMemo(
    () => [
      {
        icon: <HourglassIcon className="text-cf-green-3" />,
        header: 'Epoch',
        body: 'Funding & Redeeming enabled',
        tooltipContent:
          'An Epoch is a predefined time frame or cycle in the State Chain operation. Funding is always enabled during an epoch.',
        timeToCompletion: getEpochPhaseTimeToCompletion(auction, 'epoch'),
        epochPhase: 'epoch',
      },
      {
        icon: <GavelIcon className="text-cf-orange-2" />,
        header: 'Auction',
        body: 'Redeeming disabled',
        tooltipContent:
          'An Auction is the process by which the highest bidders who complete Keygen are selected. Redeeming is disabled during auctions.',
        timeToCompletion: getEpochPhaseTimeToCompletion(auction, 'auction'),
        epochPhase: 'auction',
      },
      {
        icon: <Rotate2Icon className="text-cf-blue-4" />,
        header: 'Rotation',
        body: 'Redeeming disabled',
        tooltipContent:
          'A Rotation occurs when the Authority is handed over from one set to the next. Redeeming is disabled during rotations.',
        timeToCompletion: getEpochPhaseTimeToCompletion(auction, 'rotation'),
        epochPhase: 'rotation',
      },
    ],
    [auction],
  );

  const currentEpochPhase = useMemo(() => auction && getCurrentEpochPhase(auction), [auction]);

  // explicit boolean check because we want this to be false when auction isn't loaded
  const safeModeActive = auction?.authorityRotationEnabled === false;

  const progressDescription = useMemo(() => {
    if (safeModeActive) {
      return { body: 'Safe mode active' };
    }

    if (currentEpochPhase === null) {
      return {
        header: <SkeletonLine className="h-[12px] md:h-[14px]" />,
        body: <SkeletonLine className="h-[12px]" />,
      };
    }

    return [
      {
        header: timeUntilAuction,
        body: 'Time until auction',
      },
      {
        header: timeUntilResolution,
        body: 'Time until rotation',
      },
      {
        body: 'Rotating...',
      },
    ][currentEpochPhase.index];
  }, [timeUntilAuction, timeUntilResolution, currentEpochPhase, safeModeActive]);

  const rotation = epochWithRotation?.rotationEvents;
  const newKeysActivatedRotationPhaseEvent = rotation?.nodes.find(
    (node) => node?.type === 'NEW_KEYS_ACTIVATED_ROTATION_PHASE',
  );

  const isRotationActive = useMemo(
    () => currentEpochPhase?.name === 'rotation' && rotation && !newKeysActivatedRotationPhaseEvent,
    [currentEpochPhase, rotation],
  );

  return (
    <StatPanel flexDirection="col" isLoading={false} className={className}>
      <div className="flex w-full flex-col justify-between space-y-4">
        <div className="text-14 text-white md:text-20">
          {loading || !epochWithRotation ? (
            <SkeletonLine />
          ) : (
            <span>Epoch {epochWithRotation.id}</span>
          )}
        </div>

        <div className="flex w-full flex-col rounded-md border border-cf-gray-4 bg-cf-gray-3 p-4 md:flex-row md:space-x-7">
          <div className="relative flex h-full items-center justify-center">
            {loading || !epochWithRotation ? (
              <SkeletonLine />
            ) : (
              <>
                {auction ? (
                  <AnimatedEpochProgress auction={auction} />
                ) : (
                  <SkeletonLine height={180} width={180} />
                )}
                {progressDescription.header !== '' && (
                  <div className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center">
                    <div className="font-aeonikMono text-16 text-white">
                      {progressDescription.header}
                    </div>
                    <div className="text-12 text-cf-light-2">{progressDescription.body}</div>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="flex grow flex-col justify-center space-y-2">
            {panels.map((panel, index) =>
              loading || !epochWithRotation ? (
                <SkeletonLine key={panel.header} />
              ) : (
                <PanelRow
                  key={panel.header}
                  panel={panel}
                  isActive={currentEpochPhase?.name === panel.epochPhase}
                  isCompleted={Boolean(currentEpochPhase) && index < currentEpochPhase!.index}
                />
              ),
            )}
          </div>
        </div>
        <div className="flex grow flex-col items-center justify-between space-y-4 rounded-md border border-cf-gray-4 bg-cf-gray-3 p-4 sm:flex-row sm:space-y-0">
          {loading || !epochWithRotation ? (
            <SkeletonLine width="100%" />
          ) : (
            <>
              <div className="flex w-full flex-row items-center justify-between space-x-4 sm:justify-start">
                <div className="flex flex-row space-x-2">
                  <RotateIcon className="text-cf-blue-4" />
                  <span>Rotation</span>
                </div>
                <div className="flex flex-row items-center justify-center gap-x-2 text-cf-light-2">
                  {isRotationActive && (
                    <>
                      <LoadingSpinner className="stroke-cf-light-2" />
                      <span className="text-14">In progress</span>
                    </>
                  )}

                  {previousEpochWithRotation && !isRotationActive && (
                    <Button
                      type="link-secondary"
                      onClick={showPreviousRotationDetailsModal}
                      className="text-12"
                    >
                      See previous rotation
                    </Button>
                  )}
                </div>
              </div>
              <div className="flex w-full sm:w-auto [&>:nth-child(1)]:w-full">
                <Tooltip
                  content="Details are available after auction is resolved."
                  disabled={isRotationActive || safeModeActive}
                >
                  <Button
                    className="w-full"
                    type={safeModeActive ? 'primary-standard' : 'secondary-standard'}
                    onClick={
                      safeModeActive
                        ? () =>
                            window.open(
                              new URL(
                                'https://docs.chainflip.io/concepts/components/governance-and-security#state-chain-safe-mode',
                              ),
                              '_blank',
                            )
                        : showCurrentRotationDetailsModal
                    }
                    disabled={!isRotationActive && !safeModeActive}
                  >
                    {safeModeActive ? 'Learn more' : 'Show details'}
                  </Button>
                </Tooltip>
              </div>
            </>
          )}
        </div>
      </div>
      <RotationInfoModal
        active={detailsModalActive}
        onCancel={hideCurrentRotationDetailsModal}
        epochWithRotation={epochWithRotation}
      />
      <RotationInfoModal
        active={oldDetailsModalActive}
        onCancel={hidePreviousRotationDetailsModal}
        epochWithRotation={previousEpochWithRotation}
      />
    </StatPanel>
  );
};

export default AuctionWidget;
